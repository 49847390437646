<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('user_role.userRoles') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
            <b-col xs="12" sm="12" md="6">
                <b-form-group
                class="row"
                label-cols-sm="3"
                :label="$t('user.namel')"
                label-for="name"
                >
                <b-form-input
                    id="name"
                    v-model="search.search_key"
                    :placeholder="$t('globalTrans.Pleasetypename')"
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col xs="12" sm="12" md="6">
                <b-form-group
                class="row"
                label-cols-sm="3"
                label-for="role_id"
                :label="$t('user_role.roles')"
                >
                <b-form-select
                    plain
                    v-model="search.role_id"
                    :options="roleList"
                    >
                    <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                </b-form-select>
                </b-form-group>
            </b-col>
        </b-row>
        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('user_role.userRoles') }}</h4>
          </template>
          <template v-slot:headerAction>
            <router-link class="btn btn_add_new" :to="{ name: 'common_service.access_control.user_role' }">
              <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
            </router-link>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table bordered hover :items="datas" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(user)="data">
                      <slot v-if="data.item">
                        {{ ($i18n.locale === 'bn') ? data.item.name_bn : data.item.name }}
                      </slot>
                    </template>
                    <template v-slot:cell(assignRole)="data">
                      <slot v-if="data.item && data.item.user_detail">
                        <b-button v-b-modal.modal-1 variant=" iq-bg-success mr-1 mb-1" size="sm" :title="$t('user.assignRole')" @click="getDesignationUser(data.item)">{{  $t('user.assignRole') }}</b-button>
                      </slot>
                    </template>
                    <template v-slot:cell(role)="data">
                        <span  v-for="(roleData, index) in data.item.role_user" :key="index">
                          <span v-if="index != 0">, </span><span>{{ ($i18n.locale === 'bn') ? roleData.role.role_name_bn : roleData.role.role_name }}</span>
                        </span >
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
      <b-modal id="modal-1" size="md" title="Role Select" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <p>
          <iq-card style="min-height: 180px;">
            <div class="card-body iq-card-body">
              <h4 class="text-center">{{  $t('user.roleList') }}</h4>
              <hr class="mt-4"/>
              <slot>
                <h5 style="height: 29px">
                  <slot v-if="data.name">
                    {{  $t('user.roleOf') }} : <b>{{data.name}}</b>
                  </slot>
                </h5>
                <hr/>
                <slot v-if="role_loader">
                  Loading ......
                </slot>
                <slot>
                  <b-form  v-if="data.roles.length>0">
                    <slot v-for="(role, index) in data.roles">
                      <b-form-checkbox
                        class="col-form-label-sm font-weight-bold mb-0"
                        v-model="role.checked"
                        name="checked"
                        :unchecked-value=false
                        :key="index"
                        >
                        {{ ($i18n.locale==='bn') ? role.role_name_bn  :  role.role_name  }}
                      </b-form-checkbox>
                    </slot>
                  </b-form>
                  <!-- <div class="panel-body text-center mt-3" v-else>
                    <h6 class="text-danger">No Data Available !!</h6>
                  </div> -->
                </slot>
              </slot>
              <b-col lg="col-lg-12 ml-0 pl-0" v-if="data.roles.length>0" style="margin-top: 18px !important;">
                <button
                  class="btn btn-primary btn-sm"
                  v-on:click="SubmitData()"
                  >
                    <i class="fas fa-list"></i>Submit
                  </button>
              </b-col>
            </div>
          </iq-card>
        </p>
      </b-modal>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { mapGetters } from 'vuex'
import RestApi, { authServiceBaseUrl } from '@/config/api_config'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
  },
  data () {
    return {
      search: {
        search_key: '',
        org_id: 0,
        role_id: 0
      },
      data: {
        designation_id: 0,
        user_id: '',
        name: '',
        roles: []
      },
      testId: 0,
      rows: [],
      user_detail: '',
      datas: [],
      allRoles: [],
      role_loader: false,
      authServiceBaseUrl: authServiceBaseUrl
    }
  },
  computed: {
    roleList: function () {
      const listObject = this.allRoles
      const tmpList = listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.id, text: obj.role_name_bn }
        } else {
          return { value: obj.id, text: obj.role_name }
        }
      })
      return tmpList
    },
    columns () {
        const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('user.user'), class: 'text-left' },
          { label: this.$t('user.role'), class: 'text-left' },
          { label: this.$t('user.assignRole'), class: 'text-left' }
        ]

        let keys = []

        if (this.$i18n.locale === 'bn') {
            keys = [
                { key: 'index' },
                { key: 'user' },
                { key: 'role' },
                { key: 'assignRole' }
            ]
        } else {
            keys = [
                { key: 'index' },
                { key: 'user' },
                { key: 'role' },
                { key: 'assignRole' }
            ]
        }

        return labels.map((item, index) => {
            return Object.assign(item, keys[index])
        })
    },
    ...mapGetters({
      commonObj: 'commonObj'
    })
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  created () {
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.search.org_id = this.$store.state.Auth.authUser.org_id
    }
    this.rolData()
    this.loadData()
  },
  mounted () {
    core.index()
  },
  methods: {
     async confirmData () {
      let result = null
      this.role_loader = true
      result = await RestApi.postData(authServiceBaseUrl, 'role/assign-role', this.data)
      if (result.success) {
        this.role_loader = false
        this.$bvModal.hide('modal-1')
        this.$toast.success({
          title: 'Success',
          message: 'Data saved successfully',
          color: '#D6E09B'
        })
        this.loadData()
      }
    },
    async SubmitData () {
      this.$swal({
        title: this.$t('globalTrans.recommendentChangeMsg'),
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        focusConfirm: false
      }).then((resultSwal) => {
        if (resultSwal.isConfirmed) {
          this.confirmData()
        }
      })
    },
    getDesignationUser (user) {
      this.data = {
        designation_id: 0,
        user_id: '',
        name: '',
        roles: []
      }
      const search = {
        org_id: user.user_detail.org_id,
        designation_id: user.user_detail.designation_id,
        office_id: user.user_detail.office_id,
        user_id: user.id,
        office_type_id: user.user_detail.office_type_id
      }
      if (search.designation_id) {
        this.role_loader = true
        RestApi.getData(authServiceBaseUrl, 'role/user-role-data', search).then(response => {
          this.data.user_id = user.id
          this.data.name = user.user_detail.name
          this.data.roles = response
          this.role_loader = false
        }, error => {
          if (error) {
            //
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        })
      }
    },
    searchData () {
      this.loadData()
    },
    details (item) {
      this.user_detail = item
    },
    remove (item) {
      this.$swal({
        title: this.$t('globalTrans.deleteMsg'),
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteData(item)
        }
      })
    },
    async loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      await RestApi.getData(authServiceBaseUrl, 'role/role-user-list', params).then(response => {
        if (response.success) {
          this.datas = response.data.data
          this.paginationData(response.data)
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      }, error => {
        if (error) {
          //
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    rolData () {
      RestApi.getData(authServiceBaseUrl, 'role/role-list-select/all', {}).then(response => {
        this.allRoles = response
      }, error => {
        if (error) {
          //
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    }
  }
}
</script>
